import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "./styled";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";


interface ButtonProps {
    title?: string;
    link?: string;
    color: string;
    onClick?: () => void,
    type?: "button" | "reset" | "submit",
    target?: "_blank"
}

export function ButtonEdit(props: ButtonProps) {
    return (

        <Component>
            <button onClick={props.onClick} type={props.type} className={props.color}><a target={props.target} href={props.link}><FontAwesomeIcon icon={faPenToSquare} /></a></button>
        </Component>

    )
}
export function ButtonDelete(props: ButtonProps) {
    return (
        <Component>
            <button onClick={props.onClick} type={props.type} className={props.color}><a target={props.target} href={props.link}><FontAwesomeIcon icon={faTrash} /></a></button>
        </Component>

    )
}