import {
  AppBar,
  CssBaseline,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import DrawerComponent from "./Drawer";
import { Component } from "./styled";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(20),
    borderBottom: "1px solid transparent",
    "&:hover": {
      fontSize: "21px"
    },
  },
}));

function Navbar() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Isso adiciona uma animação de rolar suavemente
    });
  };
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <Component>
      <AppBar position="fixed" className="navBar">
        <CssBaseline />
        <Toolbar className="toolbar">
          <Typography variant="h3" >
            <a href="/">
              Gabriel Oliveira
            </a>

          </Typography>
          {isMobile ? (
            <DrawerComponent />
          ) : (
            <div className={classes.navlinks}>
                <Link to="/" onClick={scrollToTop} className={classes.link}>
                Home
              </Link>
                <Link to="/" onClick={() => scrollToSection('aboutme')} className={classes.link}>
                About
              </Link>
              <Link to="/" onClick={() => scrollToSection('portfolio')} className={classes.link}>
                Portfolio
              </Link>
              <Link to="/" onClick={() => scrollToSection('contactme')} className={classes.link}>
                Contact
              </Link>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Component>
  );
}
export default Navbar;