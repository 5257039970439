import Social from "../../../social.json";
import { Backgrounds } from "../../Backgrounds";
import { Buttons } from "../../Buttons";
import sliderImg from "./slider-perfil.png";
import { Component } from "./styled";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";



export function Slider() {

    return (
        <Component id="top">

            <div className="myName" data-aos='fade-left'>
                <p className="hello">Hello, I'm</p>
                <p className="name">Gabriel Oliveira</p>
                <p className="subTitle"><span className="typewriter"></span></p>
                <div className="buttons">
                    <Buttons
                        title="LinkedIn"
                        link={Social.linkedin}
                        color="purple"
                        target="_blank"
                        icon={faLinkedin}
                    />
                    <Buttons
                        title="Github"
                        link={Social.github}
                        color="transparent"
                        target="_blank"
                        icon={faGithub}
                    />
                </div>

            </div>
            <img data-aos='fade-right' src={sliderImg} alt="" />
            <Backgrounds />
        </Component>

    )

}