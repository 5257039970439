import styled from "styled-components"

export const ModernCardContainer = styled.div`
  width: 353px;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 10;

    .card-content {
      z-index: 10;
      span {
        white-space: normal;
      }
      img {
        transform: scale(1.1);
      }
      ul {
        display: flex;
      }
    }
  }

  img {
    width: 100%;
    border-radius: 8px 8px 0 0;
    transition: transform 0.5s;
  }

  .card-content {
    background: #ffffff;
    padding: 20px;
    border-radius: 0 0 8px 8px;

    h3 {
      color: #333;
      margin-bottom: 10px;
    }

    span {
      display: block;
      margin: 10px 0;
      color: #777;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ul {
      display: none;
      justify-content: center;
      grid-gap: 10px;
      margin-bottom: 0.5rem;

      li {
        list-style-type: none;
        font-weight: bold;
        text-transform: uppercase;
      }
      i {
        font-size: 27px;
      }
    }
    .buttonP {
      display: flex;
      justify-content: center;
    }
  }
  @media screen and (max-width: 720px) {
    width: 330px;
  }
`
