import React, { useEffect, useState } from 'react';
import { Buttons } from '../../Buttons';
import data from './keywords.json';
import { Component } from './styled';

export function Keywords() {
  const [keywords, setKeywords] = useState<Array<string>>([]);
  const [displayCount, setDisplayCount] = useState<number>(12);

  useEffect(() => {
    setKeywords(data.keywords);
  }, []);

  const handleSeeMoreClick = () => {
    setDisplayCount(displayCount + 12);
  };

  return (
    <Component aria-label="Keywords">
      <ul>
        {keywords.slice(0, displayCount).map((keyword, index) => (
          <li key={index}>{keyword}</li>
        ))}
      </ul>
      {displayCount < keywords.length && (
        <Buttons 
        title="See more"
        onClick={handleSeeMoreClick}
        color="border-grey"
        />
        
      )}
    </Component>
  );
}