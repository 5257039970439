import portfolioData from '../../data/portfolio.json'; // Atualize o caminho para corresponder ao local real do seu arquivo JSON
import { Title } from '../Title';
import { Itens } from './Itens';
import { Component } from './styled';

;

type Repository = {
    title: string
    description: string
    image: string
    id?: string
    language: string[]
    languageIcon?: string
    link: string
}

export function Portfolio() {
    const data: Repository[] = portfolioData.portfolio // Acessando a propriedade 'portfolio'

    return (
        <Component id="portfolio">
            <Title
                title="Portfolio"
                subTitle="Check out my portfolio projects below"
                color="h1-green"
                colorh3="h3-grey"
            />

            <div className="portList">
                {data.slice().reverse().map(repo => (
                    <Itens
                        key={repo.id}
                        description={repo.description}
                        title={repo.title}
                        img={repo.image}
                        language={repo.language}
                        link={repo.link}
                    />
                ))}
            </div>

        </Component>
    );
}