import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`

:root{
     --body-bg-color: #22212c;
  --text-color: #837e9f;
  --bg-cards: #302f3d;
  --bg-techs: #cb92b1;
  --limon: #00ff00;
  --invalid: #dc0342;

    --blue: #00D7FE;
    --blue-darken:#0097b3;
    --purple: #2F1755;
    --purple-brightness: #5F32A7;
    --green: #03DCA5;
    --green-darken: #03AAA8;
    --grey: #A2A2A2;
}


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    :focus{
        outline: none;
    }
}

a{
    text-decoration: none;
}

img{
    max-width: 100%;
}

html{
        scroll-behavior: smooth;
        @media (max-width: 1080px) {
            font-size: 93.75%; // 15px
        }

        @media (max-width: 720px){
            font-size: 87.5%; // 14px
        }
    }

    body{
        background-color: var(--body-bg-color);
    }

    body, input, textarea, button {
        font-family: 'Ubuntu', sans-serif;
        }
    
    body::-webkit-scrollbar {
        width: 12px;               
        }

    body::-webkit-scrollbar-track {
        background: transparent;       
    }

    body::-webkit-scrollbar-thumb {
        background-color: var(--blue);  
        border-radius: 20px;       
  
    }
`
