import styled from "styled-components"

export const Component = styled.div`
  display: grid;
  place-items: center;
  background-color: var(--body-bg-color);

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.5rem;
    margin-top: 15px;
  }

  input[type="text"],
  input[type="url"],
  select,
  textarea {
    background-color: #202024;
    outline: 2px solid var(--text-color);
    height: 48px;
    width: 450px;
    border: none;
    color: #fff;
    border-radius: 5px;
    padding: 0 12px;
    font-size: 16px;
    transition: all 0.4s;

    :required:valid {
      outline: 2px solid var(--green);
    }
  }

  textarea {
    padding: 0.5rem;
    height: 95px;
  }

  select {
    width: 450px;
    height: 150px;
    ::-webkit-scrollbar {
      display: none;
    }
    ::-moz-scrollbar {
      display: none;
    }
    ::-o-scrollbar {
      display: none;
    }

    option {
      line-height: 24px;
    }
  }

  label {
    margin-bottom: 5px;
    cursor: pointer;

    p {
      width: 450px;
      text-align: center;
      background-color: var(--blue);
      border-radius: 5px;
      font-weight: bolder;
      color: #fff;
      padding: 0.8rem;
      transition: 0.4s;
      :hover {
        filter: contrast(0.8);
      }
    }
    input[type="file"] {
      display: none;
    }
  }

 .button-close {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--invalid); 
  color: #fff;
  font-weight: bolder;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  width: 20px;
  height: 20px;
}

  button {
    width: 450px;
  }
`
