import { Form } from "./Form";
import {  Component } from "./styled";



export function Contact ( ){
    return(
        <Component id="contactme">
            
            <Form />
           
           
           
        </Component>
        
    )
}