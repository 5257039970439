import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { Buttons } from '../../Components/Buttons';
import { Form } from '../Form';
import { Component } from './styled';

const customStyles = {
    overlay:{
        zIndex: 25,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        
    },
  content: {
    with: '500px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#22212c',
  },
};

export function Modals() {
    //@ts-ignore
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //@ts-ignore
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    Modal.setAppElement('#yourAppElement');
  }, []);

  return (
    <Component id='yourAppElement' >
        <Buttons 
        color='green'
        title='Add Card'
        onClick={openModal}
        />

        <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
              >
         <Buttons 
        color='transparent'
        title='Close'
        onClick={closeModal}
        />
        
        <div ref={(_subtitle) => (subtitle = _subtitle)}>
            <Form />
        </div>
      </Modal>
    </Component>
  );
}
