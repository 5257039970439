import { Buttons } from "../Buttons";
import { Component } from "./styled";
import solutionsImg from "../../assets/solutions.png"

export function Solutions (){
    return(
        <Component id="solutions">
            
            <div className="solutions" data-aos='fade-right' >
                
            <h1>Need Create Front End Design? <br />
            <strong>I can Help You!</strong></h1>
            <p>With current tools, I can help you in your journey to create a <strong>Front End Design </strong>for you or your business. Check out my portfolio and contact me for futher information.</p>
            <div className="buttons"><Buttons 
                title ='Contact Me'
                color ='green'
                link ='#contactme'
                /></div>
            
            </div>
            
            <img className="solution" data-aos='fade-left' src={solutionsImg} alt="" />
            
            
        </Component>
    )
}