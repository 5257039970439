import { faGithub, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faMapLocation, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from "react";
import Social from "../../../social.json";
import { Buttons } from "../../Buttons";
import { Content } from "./styled";


export function Form() {
    const [status, setStatus] = useState("Submit");
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setStatus("Sending...");
        const { name, email, subject, message } = e.target.elements;
        const details = {
            "name": name.value,
            "email": email.value,
            "subject": subject.value,
            "message": message.value
        };

        try {
            let response = await fetch("/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify(details),
            });

            let result = await response.json();

            console.log(details.name);
            setStatus("Sent");
            return result;
        } catch (error) {
            console.log("error:", error);
            alert(error);
            setStatus("Submit");
        } finally {
            const success = document.querySelector('#success');
            if (success) {
                success.innerHTML = "<p>Message sent successfully!</p>";
                setStatus("Sent");
                setTimeout(() => {
                    setStatus("Submit");
                    name.value = '';
                    email.value = '';
                    subject.value = '';
                    message.value = '';

                }, 5000); // Remove it after 5 seconds (adjust the time as needed)
            }
        }

    };


    return (
        <Content >
            <hgroup>
                <h1>Hire Me</h1>
                <p>Any questions or remarks? Just write me a message!</p>
                <ul>
                    <li>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <a href={Social.email}>contact@gabrielvoliveira.com</a>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faPhone} />
                        <a href={Social.phone}>+351 960169746</a>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faMapLocation} />
                        <a href="/">Portugal</a>

                    </li>
                </ul>
                <div className="Social">

                    <a rel="nooper noreferrer" target="_blank" href={Social.instagram}><FontAwesomeIcon icon={faInstagram} /></a>

                    <a rel="nooper noreferrer" target="_blank" href={Social.linkedin}><FontAwesomeIcon icon={faLinkedin} /></a>

                    <a rel="nooper noreferrer" target="_blank" href={Social.github}><FontAwesomeIcon icon={faGithub} /></a>

                </div>

            </hgroup>

            <form onSubmit={handleSubmit} data-netlify="true">
                Name*
                <input
                    id="name"
                    required />
                Email*
                <input
                    type='email'
                    id="email"
                    required
                />
                Subject
                <input
                    id="subject"
                />
                Message*
                <textarea
                    id="message"
                    required
                />

                <Buttons
                    type="submit"
                    title={status}
                    color="green"
                />
            </form>
        </Content>
    )
}