import { Component } from "./styled";
import { ButtonDelete, ButtonEdit } from "../../Buttons";
import Modal from 'react-modal';
import { Form } from "../../Form";
import { Buttons } from "../../../Components/Buttons";
import React, { useEffect } from "react";

interface listProps {
    img?: string,
    description?: string,
    title?: string,
    language?: string[],
    link?: string
}


const customStyles = {
    overlay: {
        zIndex: 25,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',

    },
    content: {
        with: '500px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#22212c',
    },
};
export function Itens(props: listProps) {
    //@ts-ignore
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        //@ts-ignore
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        Modal.setAppElement('#yourAppElement');
    }, []);

    return (
        <Component>
            <div className="portfolio theme">
                <img src={props.img} alt="" />

                <div className="content-port">
                    <h3>{props.title}</h3>
                    <span>{props.description}</span>
                    <ul>

                        {props.language?.map(lang => (
                            <li key={lang}>{lang}</li>
                        ))}
                    </ul>
                    <div className="buttonP">
                        <ButtonEdit
                            color="green"
                            onClick={openModal}
                        />
                        <Modal
                            isOpen={modalIsOpen}
                            onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customStyles}
                        >
                            <Buttons
                                color='transparent'
                                title='Close'
                                onClick={closeModal}
                            />

                            <div ref={(_subtitle) => (subtitle = _subtitle)}>
                                <Form />
                            </div>
                        </Modal>

                        <ButtonDelete
                            color="red"
                        //Colar aqui o onClick
                        />
                    </div>
                </div>
            </div>

        </Component>
    );
}