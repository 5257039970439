import AOS from 'aos';
import 'aos/dist/aos.css';
import { Waves } from '../Components/Backgrounds';
import { Contact } from '../Components/Contact';
import { Footer } from '../Components/Footer';
import Navbar from '../Components/Header/Menu/Nav';
import { Slider } from '../Components/Header/Slider';
import { Introduction } from '../Components/Introduction';
import { Portfolio } from '../Components/Portfolio';
import { Solutions } from '../Components/Solutions';
import { GlobalStyle } from '../Styles/global';

AOS.init();

function Home() {


  return (
    <>
      <GlobalStyle />
      <Navbar />
      <Slider />
      <Introduction />
      <Solutions />
      <Portfolio />
      <Contact />
      <Waves />
      <Footer />
    </>


  );
}

export default Home;
