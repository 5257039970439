import { Itens } from './Itens';
import portfolioData from '../../data/portfolio.json'; // 
import { Component } from './styled';

type Repository = {
    title: string
    image: string
    id?: string

}

export function Menager() {
    const data: Repository[] = portfolioData.portfolio
    return (
        <Component id="portfolio">

            <div className="portList">
                {data.map(repo => (
                    <Itens
                        key={repo.id}
                        title={repo.title}
                        img={repo.image}
                    />
                ))}
            </div>
        </Component>
    );
}