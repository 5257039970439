import styled from "styled-components";

export const Component = styled.div`


ul{
    display: flex;
    flex-wrap: wrap;
    
    li{
        border: 1px solid var(--grey);
        border-radius: 25px;
        padding:0.5rem;
        text-transform: capitalize;
        margin-right: 2px;
        margin-top: 2px;
        font-size: 0.8rem;

        :last-child{
            margin-right: 0;
        }
    }
}



`