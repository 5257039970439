import { Modals } from "./Modal";
import { GlobalStyle } from "./Styles/global";

import { Menager } from "./gestor";


export function Admin() {
  return (
    <>
      <GlobalStyle />
      <Menager />
      <Modals />
    </>

  )
}


