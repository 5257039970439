import styled from "styled-components";

export const Component = styled.div`
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #fafafa;

  ::before {
    position: absolute;
    align-self: end;
    left: -152px;
    width: 18rem;
    height: 9.8rem;
    content: "";
    background-color: var(--purple);
    border-radius: 10rem 10rem 0 0;
    @media (max-width: 1300px) {
      width: 15rem;
      height: 6.79rem;
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }
  .solutions {
    width: 28%;
    display: grid;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
    margin-left: -55px;
    
    @media (max-width: 1300px){
      row-gap: 1rem;
    }

    @media (max-width: 1024px) {
      width: 73%;     
    }
    
    h1 {
      font-size: 3rem;
      strong {
        font-weight: lighter;
      }
    }

    p {
      text-align: center;
    }
  }

  .solution {
    padding-left: 4rem;
    width: 725px;
    max-width: 100%;
  }
  .buttons {
    display: flex;
    justify-content: center;
  }

  .purple-cicle {
    width: 150px;
    margin-bottom: -280px;
  }

  @media (max-width: 1775px) {
    .purple-cicle {
      width: auto;
      margin-bottom: -220px;
    }
  }
`;
