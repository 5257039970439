import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../Home";
import { Admin } from "../Admin";

const Rota = () => {
  return (
    <Router>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Admin />} path="/admin" />
      </Routes>
    </Router>
  );
};

export default Rota;
