import styled from "styled-components";


export const Component = styled.hgroup`
margin-bottom: 1rem;
    text-align: center;
    color: #fff;
    .h1-purple{
      color: var(--purple);
    }
    .h1-blue{
      color: var(--blue);
    }
    .h1-green{
      color: var(--green);
    }
    
    .h3-grey{
      color: var(--grey);
    }
    .h3-with{
      color: #fff;
    }
    
`;