import styled from "styled-components";

export const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem 2rem 4rem 2rem;
  background-color: var(--blue);
  
   
  img{
    max-width: 100%;
    width: 600px;
    padding: 3rem 0 0 3rem;
  }
  

`;

