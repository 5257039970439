import styled from "styled-components"

export const Component = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem;

  .portList {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fill, minmax(353px, 1fr));
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
`
