import { Content } from "./styled";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Social from "../../social.json"
import { Keywords } from "./Tags";
import { faGithub, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";



export function Footer() {
    return (
        <Content>

            <div>
                <h2>About me</h2>
                <p>I'm deeply passionate about creating innovative projects and I firmly believe in their power to transform lives. With a keen interest in the entire front-end spectrum, I strive to work on ambitious and cutting-edge projects that push the boundaries of what's possible.</p>
            </div>
            <div className="footerContact">
                <h2>Contact Me</h2>
                <p>If you have any questions, please contact me.</p>
                <ul>
                    <li>
                        <i className="fa fa-phone"></i>
                        <a href={Social.phone}> +351 96016-9746</a>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <a href={Social.email}> contact@gabrielvoliveira.com</a>
                    </li>
                    <nav className="social">

                        <h4>Social Media</h4>

                        <a rel="nooper noreferrer" target="_blank" href={Social.instagram}><FontAwesomeIcon icon={faInstagram} /></a>
                        <a rel="nooper noreferrer" target="_blank" href={Social.linkedin}><FontAwesomeIcon icon={faLinkedin} /></a>
                        <a rel="nooper noreferrer" target="_blank" href={Social.github}><FontAwesomeIcon icon={faGithub} /></a>

                    </nav>
                </ul>
            </div>
            <div>
                <h2>Keywords</h2>
                <Keywords />
            </div>

            <div className="copyright">
                <p>Copyright by <strong>Gabriel Oliveira</strong></p>
            </div>
        </Content>
    )
}