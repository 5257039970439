import styled from "styled-components"

export const Component = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  background: linear-gradient(132deg, var(--blue), #591bc5, #212335);
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
  padding-top: 4rem;

  @media (max-width: 600px) {
    justify-content: center;

    .myName {
      padding-left: 4rem;
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .myName {
    z-index: 10;

    .hello {
      color: #fff;
      font-size: 4rem;
      font-weight: lighter;
    }

    .name {
      color: var(--blue);
      font-size: 4.5rem;
      font-weight: 700;
    }

    .subTitle {
      color: #fff;
      width: 150px;
      font-weight: bolder;
      font-size: 2rem;
      margin-bottom: 1rem;

      /* Typewriter effect 1 */
      @keyframes typing {
        0.0000%,
        27.3488% {
          content: "";
        }
        1.1395%,
        26.2093% {
          content: "d";
        }
        2.2791%,
        25.0698% {
          content: "de";
        }
        3.4186%,
        23.9302% {
          content: "dev";
        }
        4.5581%,
        22.7907% {
          content: "deve";
        }
        5.6977%,
        21.6512% {
          content: "devel";
        }
        6.8372%,
        20.5116% {
          content: "develo";
        }
        7.9767%,
        19.3721% {
          content: "develop";
        }
        9.1163%,
        18.2326% {
          content: "developer";
        }
        10.2558%,
        17.0930% {
          content: "developer";
        }

        30.7674%,
        51.2791% {
          content: "";
        }
        31.9070%,
        50.1395% {
          content: "C";
        }
        33.0465%,
        49.0000% {
          content: "CS";
        }
        34.1860%,
        47.8605% {
          content: "CSS";
        }
        35.3256%,
        46.7209% {
          content: "CSS L";
        }
        36.4651%,
        45.5814% {
          content: "CSS Lov";
        }
        37.6047%,
        44.4419% {
          content: "CSS Love";
        }
        38.7442%,
        43.3023% {
          content: "CSS Lover";
        }

        54.6977%,
        75.2093% {
          content: "";
        }
        55.8372%,
        74.0698% {
          content: "d";
        }
        56.9767%,
        72.9302% {
          content: "de";
        }
        58.1163%,
        71.7907% {
          content: "des";
        }
        59.2558%,
        70.6512% {
          content: "desi";
        }
        60.3953%,
        69.5116% {
          content: "desig";
        }
        61.5349%,
        68.3721% {
          content: "designe";
        }
        62.6744%,
        67.2326% {
          content: "designer";
        }
        63.8139%,
        66.0930% {
          content: "designer";
        }

        78.6279%,
        96.8605% {
          content: "";
        }
        79.7674%,
        95.7209% {
          content: "g";
        }
        80.9070%,
        94.5814% {
          content: "ga";
        }
        82.0465%,
        93.4419% {
          content: "gam";
        }
        83.1860%,
        92.3023% {
          content: "game";
        }
        84.3256%,
        91.1628% {
          content: "gamer";
        }
        85.4651%,
        90.0233% {
          content: "gamer";
        }
        86.6047%,
        88.8837% {
          content: "gamer";
        }
        87.7442%,
        87.7442% {
          content: "gamer";
        }
      }

      @keyframes blink {
        0%,
        100% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
      }

      .typewriter {
        --caret: currentcolor;
      }

      .typewriter::before {
        content: "";
        animation: typing 13.5s infinite;
      }

      .typewriter::after {
        content: "";
        border-right: 1px solid var(--caret);
        animation: blink 0.5s linear infinite;
      }

      .typewriter.thick::after {
        border-right: 1ch solid var(--caret);
      }

      .typewriter.nocaret::after {
        border-right: 0;
      }

      @media (prefers-reduced-motion) {
        .typewriter::after {
          animation: none;
        }

        @keyframes sequencePopup {
          0%,
          100% {
            content: "developer";
          }
          25% {
            content: "CSS lover";
          }
          50% {
            content: "designer";
          }
          75% {
            content: "gamer";
          }
        }

        .typewriter::before {
          content: "developer";
          animation: sequencePopup 12s linear infinite;
        }
      }
    }

    .buttons {
      display: inline-flex;
      justify-content: space-between;
      width: 17rem;
    }
  }
  img {
    max-width: 576px;
    margin-left: -4rem;
    z-index: 100;
    @media (max-width: 800px) {
      width: 367px;
    }
  }
`
