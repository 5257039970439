import { Component } from "./styled";

interface titleProps{
    title: string,
    subTitle?: string
    color?:string
    colorh3?:string
}

export function Title (props: titleProps) {
     
    return(
        <Component>
            
                <h1 className={props.color} >{props.title}</h1>
                 <h3 className={props.colorh3}> {props.subTitle} </h3>
            </Component>
    )
}