import React, { useState } from "react";
import { Buttons } from "../../Components/Buttons";
import { Component } from "./styled";

export function Form() {
  const [pColor, setPColor] = useState<string>("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = e.target;
    setPColor(fileInput.validity.valid ? "#00ff00" : "red");

    if (fileInput.files && fileInput.files[0]) {
      setImagePreviewUrl(URL.createObjectURL(fileInput.files[0]));
    } else {
      setImagePreviewUrl(null);
    }
  };

  const handleImageCancel = () => {
    // Reset the preview image state and file input
    setImagePreviewUrl(null);
    const fileInput = document.getElementById('image') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  };

  return (
    <Component>
      <form action="" >
        <input id="title" type="text" placeholder="Title" required />
        <textarea id="description" required placeholder="Description" cols={4} />
        <input id="link" type="url" placeholder="Link" pattern="https://.*" required />
        <select id="language" name="lenguage" multiple required>
          <option value="html">html</option>
          <option value="css">css</option>
          <option value="javascript">javascript</option>
          <option value="react">react</option>
          <option value="php">php</option>
          <option value="wordpress">wordpress</option>
          <option value="vue">vue</option>
        </select>
        {imagePreviewUrl && (
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <img src={imagePreviewUrl} alt="Selected preview" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
            <button
              type="button"
              className="button-close"
              onClick={handleImageCancel}
            >
              X
            </button>
          </div>
        )}
        <label htmlFor="image">
          <p style={{ background: pColor }}>Select Image</p>
          <input
            id="image"
            type="file"
            onChange={handleFileInputChange}
            required
          />
        </label>


        <Buttons
          title="Submit"
          color="green"
        />
      </form>
    </Component>
  )
}
