import styled from "styled-components";
export const Item = styled.div`
display: inline-flex;
align-items: flex-start;
padding-bottom: 1rem;
&:nth-child(3){
        padding-bottom: 0;
    }
svg{
    font-size: 1.2rem;
    font-weight: bold;
}

`;