import styled from "styled-components";
export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  margin-top: 25px;
  justify-content: center;
  align-items: baseline;
 

  div {
    display: grid;
    row-gap: 1rem;
    width: 28rem;
    padding: 3px 3px 1rem 3px;
    justify-content: center;
    
    @media (max-width: 1300px){
       width: 25rem;
      }
    h2 {
      align-self: center;
    }
    p{
      width: 361px;
    }
    ul {
        
      li {
        list-style-type: none;
        color: var(--grey);
        &:first-child {
            margin-bottom: 0.5rem;
        }
        a {
          color: var(--grey);
          font-size: 1.1rem;
        }
      }
    }

    .social {
      padding: 1rem 0 0 0;         

      a {
        margin: 0.7rem 0.5rem 0 0;
      
      
         color: var(--grey);
        font-size: 1.8rem;
        padding-top: 0.5rem;
        }
        .fa-instagram {
          :hover {
            color: #285aeb;
          }
        }
        .fa-linkedin {
          :hover {
            color: #0077b5;
          }
        }
        .fa-github {
          :hover {
            color: #000;
          }
      }
    }
  }

  .copyright {
    display: flex;
    position: inherit;
    bottom: 0;
    width: 100%;
    height: 2rem;
    background-color: #fafafa;
    color: #fff;

    p {
      color: var(--green);
      position: absolute;
      right: 0;
      margin-right: 5px;
    }
  }
`;
