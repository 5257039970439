import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { DevBox } from '../devBox';
import { TimeLine } from './TimeLine';
import { Topics } from './Topics';
import { faCode, faBullseye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import { Item } from './styled';




export function AboutTab() {
    return (
        <Tabs disableUpDownKeys={true}>
            <TabList>
                <Tab>About Me</Tab>
                <Tab>Education</Tab>
                <Tab>Timeline</Tab>
            </TabList>

            <TabPanel>
                <DevBox />
            </TabPanel>

            <TabPanel>




                <Item>
                    <FontAwesomeIcon icon={faBullseye} />
                    <Topics
                        link='https://www.faculdadeflamingo.com.br/marketing'
                        theme='Marketing'
                        place='Flamingo College'
                        date='2014'
                        description='Focusing on the main areas of direct, industrial, corporate, retail'
                        seDescription='and services marketing.'
                    />
                </Item>



                <Item>
                    <FontAwesomeIcon icon={faCode} />
                    <Topics
                        link='https://www.devmedia.com.br/carreira-programador/?slug=front-end'
                        theme='HTML CSS JAVASCRIPT'
                        place='DevMedia'
                        date='2021'
                        description='Programming web pages and applications, from basic contact to'
                        seDescription='programming to initial contact with more complex web languages.'
                    />
                </Item>

                <Item>
                    <FontAwesomeIcon icon={faReact} />
                    <Topics
                        link='https://www.rocketseat.com.br/ignite'
                        theme='React JS and TypeScript'
                        place='RocketSeat Ignite'
                        date='2021 in Progress'
                        description='Ignite is an acceleration program focused on specialization, '
                        seDescription='for those who already master programming.'
                    />
                </Item>


            </TabPanel>
            <TabPanel>
                <TimeLine />


            </TabPanel>

        </Tabs>
    );



}