import styled from "styled-components";

export const Component = styled.p`
    margin-left: 5px;
    
    a{
        color:#363636;
        :hover{
            color:var(--blue);
        }
    }
    

`;