import styled from "styled-components"

export const Component = styled.div`
  display: inline-flex;
  position: relative;
  padding: 0.5rem;

  .portfolio {
    display: flex;
    justify-content: space-between;
    width: 353px;
    overflow: hidden;
    border-radius: 8px;

    img {
      filter: brightness(85%);
      border-radius: 2%;
      transition: transform 0.5s;
      max-width: 100%;
    }

    :hover {
      transition-duration: 0.2s;

      img {
        transform: scale(1.1);
      }

      .content-port {
        transition: 0.5s;

        ul,
        .buttonP {
          display: flex;
        }
      }
    }
  }
  .content-port {
    position: absolute;
    display: grid;
    bottom: 8px;
    max-width: 96% !important;
    border-radius: 0 0 8px 8px;
    padding: 25px 20px;
    margin: auto;
    width: 100%;
    row-gap: 0.5rem;
    z-index: 4;
    background: url(https://wp.alithemes.com/html/genz/demos/assets/imgs/page/homepage3/bg-trans.png)
      left;

    span {
      color: #fff;
      font-style: italic;
      text-align: initial;
      font-size: 14px !important;
      line-height: 20px !important;
      font-weight: 400;
    }

    h3 {
      color: #fff;
      text-align: left;
    }
    ul {
      display: none;
      justify-content: center;
      grid-gap: 10px;

      li {
        list-style-type: none;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    .buttonP {
      display: none;
      justify-content: center;
      gap: 0.5rem;
    }
  }
`
