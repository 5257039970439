import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from "./styled";



interface ButtonProps {
    title: string;
    link?: string;
    color: string;
    onClick?: () => void;
    type?: "button" | "reset" | "submit";
    target?: "_blank";
    icon?: IconDefinition;
}

export function Buttons(props: ButtonProps) {
    return (
        <Component>
            <button onClick={props.onClick} type={props.type} className={props.color}>
                {props.icon !== undefined && <FontAwesomeIcon icon={props.icon} />}
                <a target={props.target} href={props.link}>{props.title}</a>
            </button>
        </Component>
    );
}
