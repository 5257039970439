import React from "react";
import { ModernCardContainer } from "./styled";
import { Buttons } from "../../Buttons";

interface MediaCardProps {
    img?: string;
    description: string;
    title: string;
    language?: string[];
    link?: string;
}

export const Itens: React.FC<MediaCardProps> = ({ img, title, description, link, language }) => {
    const getLanguageIconClass = (lang: string) => {
        type IconTypeOverrides = {
            [key: string]: string;
        };
        const iconTypeOverrides: IconTypeOverrides = {
            magento: 'original colored'
        };
        const iconType = iconTypeOverrides[lang.toLowerCase()] || 'plain colored';
        return `devicon-${lang.toLowerCase()}-${iconType}`;
    }

    return (
        <ModernCardContainer>
            <img src={img} alt="" />
            <div className="card-content">
                <h3>{title}</h3>
                <span>{description}</span>
                <ul>
                    {language?.map(lang => (
                        <li key={lang}>
                            <i className={getLanguageIconClass(lang)} title={lang}></i>
                        </li>
                    ))}
                </ul>
                <div className="buttonP">
                    <Buttons
                        target="_blank"
                        title="view details"
                        onClick={() => window.open(link, "_blank")}
                        color="green"
                    />
                </div>
            </div>
        </ModernCardContainer>
    );
}
