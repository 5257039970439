import styled from "styled-components"

export const Content = styled.div`
  display: inline-flex;
  flex-direction: inherit;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 1.8rem;

  hgroup {
    background-color: var(--purple);
    border-radius: 2% 0 0 2%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    position: relative;
    h1 {
      color: var(--blue);
    }
    p {
      color: #fff;
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }
    ul {
      display: flex;
      column-gap: 1rem;
      flex-direction: column;
      row-gap: 2rem;

      li {
        list-style-type: none;
        display: flex;
        flex-direction: initial;
        align-items: center;
        align-content: center;
        margin-right: 8px;
        column-gap: 0.5rem;

        &:last-child {
          margin: 0;
        }

        &:first-child {
          text-align: center;
        }
        :hover {
          svg {
            background-color: var(--blue);
            color: var(--purple);
            transition: 0.2s;
          }
          a {
            color: var(--blue);
            transition: 0.2s;
          }
        }

        svg {
          background-color: var(--green);
          padding: 1rem;
          border-radius: 50%;
          width: 1rem;
          color: var(--purple);
        }

        a {
          color: #fff;
          align-self: center;
        }
      }
    }
    .Social {
      display: flex;
      flex-direction: initial;
      align-self: flex-start;
      background: none;
      padding: 0.2rem;
      position: absolute;
      bottom: 50px;
      column-gap: 0.5rem;

      @media (max-width: 800px) {
        position: static;
      }
      a {
        background: none;
        padding: 0.5rem;

        svg {
          color: var(--green);
          font-size: 1.5rem;
        }

        :hover {
          background-color: var(--green);
          border-radius: 50%;
          transition: 0.2s;
          svg {
            transition: 0.2s;
            color: var(--purple);
          }
        }
      }
    }
    ::before {
      background-color: var(--green);
      opacity: 95%;
      width: 122px;
      height: 227px;
      border-radius: 10rem 0 0 10rem;
      content: "";
      position: absolute;
      align-self: end;
      bottom: 0;
      right: 0;
    }
    ::after {
      background-color: var(--blue);
      opacity: 95%;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      content: "";
      position: absolute;
      align-self: end;
      bottom: 130px;
    }
  }

  form {
    display: grid;
    background-color: #fff;
    padding: 2rem;
    row-gap: 0.5rem;
    border-radius: 0 2% 2% 0;
    textarea {
      height: 122px !important;
    }
    input,
    textarea {
      width: 25rem;
      height: 45px;
      padding: 0.5rem;
      border: 0;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
      font-size: 1rem;
      :focus {
        border-bottom: 2px solid var(--purple);
        ::placeholder {
          opacity: 1;
        }
      }
      ::placeholder {
        transition: all 0.3s;
      }
    }

    label {
      position: absolute;
      left: 10px;
      top: 14px;
      transition: all 0.2s;
      padding: 0.2px;
      z-index: 1;
      color: #b3b3b3;

      ::before {
        content: "";
        height: 5px;
        background: #272530;
        position: absolute;
        left: 0px;
        top: 10px;
        width: 100%;
        z-index: -1;
      }
    }
  }
`
