import React from "react";
import Rota from "./routes";


const App = () => {
  return (
    <Rota />
  );
};

export default App;
