import { List } from "./List"
import { Component } from "./styled"

export function TimeLine() {
  return (
    <Component>
      <div className="workSpace">
        <ul>
          <List
            date='2012 to 2014'
            company="MidiData"
            office="WebDesigner"
            desp="Creating visual website designs,
            Developing user-friendly interfaces,
            Enhancing user experience through graphic design,
            Ensuring website usability via testing and analysis,
            Maintaining and updating websites to stay current."
          />
          <List
            date='2014 to 2015'
            company="Flamingo College"
            office="Marketing Assistent"
            desp="As a marketing assistant, I assist with implementing 
            marketing strategies and campaigns, conduct research,
             and analyze data to support decision-making. I'm also 
             experienced in content creation and social media management
              to help grow and engage the audience."
          />
          <List
            date='2019 to 2021'
            company="NOS"
            office="Seller"
            desp="As a sales assistant at NOS in Portugal,
             I was responsible for presenting and selling 
             products and services, helping customers choose
              the best option for their needs. I also provided
               after-sales support, ensuring customer satisfaction
                and meeting sales targets."
          />
          <List
            date='Currently'
            company="FreeLancer"
            office="Front End Developer"
            desp="As a freelancer, I work independently and provide
             high-quality services to clients on a project basis while
              valuing clear communication and attention to detail."
          />
        </ul>
      </div>


    </Component>

  )
}